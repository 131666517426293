import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Hero, About, Jobs, Featured, Projects, Contact } from '@components';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <StyledMainContainer className="fillHeight">
      <div>
      <br/><br/>
      <br/><br/>
      <h1 >POLÍTICA DE PRIVACIDAD</h1>
    <h2>INSCRIPCIÓN – FORMULARIO DE INSCRIPCIÓN</h2>
    <p><strong>RESPONSABLE:</strong> RACKSLABS, S.L.</p>
    <p><strong>FINALIDAD:</strong> Gestionar su inscripción a softwares de Rackslabs.</p>
    <p><strong>BASE JURÍDICA:</strong> Relación comercial y consentimiento del USUARIO.</p>
    <p><strong>DESTINATARIOS:</strong> Sus datos no se cederán.</p>
    <p><strong>CONSERVACIÓN:</strong> Doce (12) meses.</p>
    <p><strong>DERECHOS DEL INTERESADO:</strong> Acceder, rectificar y cancelar los datos referentes a su persona, oponerse al tratamiento de los datos, solicitar la limitación del tratamiento, o portabilidad de los datos.</p>
    <p>De acuerdo con lo establecido en la Ley 29/2021, de 28 de octubre, Calificada de Protección de Datos personales (LQPD), así como el Decreto 391/2022, del 28-9-2022, de aprobación del Reglamento de aplicación de la Ley 29/2021, del 28 de octubre, Calificada de Protección de Datos personales, le informamos que sus datos de carácter personal recogidos a través del “Formulario de Inscripción”, serán tratados por el Responsable del Tratamiento:</p>
    <p><strong>Responsable del tratamiento:</strong> RACKSLABS, S.L.</p>
    <p><strong>NRT:</strong> L-717403-B</p>
    <p><strong>Dirección:</strong> pont de la tosca local 3</p>
    <p><strong>Teléfono:</strong></p>
    <p><strong>Correo electrónico:</strong> hello@rackslabs.com</p>
    <p>Sus datos serán tratados con el fin de gestionar sus datos en el software Rackslabs que tendrá lugar el próximo 30 de septiembre de 2023. Nos podremos poner en contacto con usted a través de correo electrónico o por vía telefónica, a razón de los datos que nos ha facilitado. La base legitimadora del tratamiento de sus datos personales se fundamenta en nuestra relación comercial y en su consentimiento otorgado a través de la aceptación de la presente política de privacidad.</p>
    <p>Así mismo, le informamos que el tratamiento de sus datos no comporta decisiones automatizadas, ni la elaboración de perfiles con fines predictivas de preferencias personales, comportamientos o actitudes. Sus datos no serán cedidos a otros responsables, nacionales y/o internacionales; a excepción de aquellos casos en que exista una obligación legal de hacerlo. Los datos proporcionados se conservarán durante un periodo de doce (12) meses.</p>
    <p>Aprovechando la ocasión le comunicamos que puede ejercer los derechos de acceso, rectificación, oposición, supresión, limitación, y portabilidad, así como su decisión a no ser objeto de decisiones individuales automatizadas; poniéndose en contacto con el Responsable del Tratamiento a través del correo electrónico hello@heavydeveloper.com o con nuestros Delegados de Protección de Datos en hello@heavydeveloper.com</p>
    <p>Así mismo, en caso de no poder ejercer sus derechos ante el Responsable del Tratamiento, tiene usted derecho a dirigir su reclamación a la Agencia Andorrana de Protección de Datos (APDA).</p>
    <p>Última actualización: 18 de agosto de 2023.</p>
      </div>
      
    </StyledMainContainer>
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
